<template>
  <div class="home background-img-1 flx flx-d-column fx-center">
    <div class="mancha-1">
      <img src="../assets/img/element-1.png" alt="imagem" />
    </div>
    <div class="flx j-c-center a-i-center">
      <img src="../assets/img/logo.png" alt="logo" width="50%" />
    </div>
    <div class="flx j-c-center a-i-center flx-d-column">
      <h2 class="title">
        <b>SEJA BEM VINDO</b>
      </h2>
      <h2 class="title">A SOLUÇÃO NA PALMA DA SUA MÃO PARA SEUS VÍDEOS NUGGETS</h2>
    </div>
    <div class="fx fx-center">
      <img class="image-center" src="../assets/img/format-1.png" alt="formats" />
    </div>
    <div class="fx-center">
      <router-link to="/login">
        <button class="btn">Começar</button>
      </router-link>
    </div>
    <div class="mancha-2">
      <img src="../assets/img/element-2.png" alt="imagem" />
    </div>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: "Home",
};
</script>
